import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  goBack() {
    this.buttonTarget.disable = true;
    const backPath = this.buttonTarget.dataset.backPath;
    window.location.href = backPath;
  }
}
