export default function updateServiceSelection(data) {
  const serviceCardTemplate = document.getElementById('service-card-template');
  const noResultsTemplate = document.getElementById('no-results-template');
  const activeButtonTemplate = document.getElementById('active-service-button-template');
  const pausedButtonTemplate = document.getElementById('paused-service-button-template');
  const serviceSelectionContainer = document.getElementById('service-selection');

  if (!data.append) {
    serviceSelectionContainer.innerHTML = '';
  }

  if (data.services.length === 0) {
    const noResultsClone = document.importNode(noResultsTemplate.content, true);
    serviceSelectionContainer.appendChild(noResultsClone);
  } else {
    data.services.forEach(service => {
      const cardClone = document.importNode(serviceCardTemplate.content, true);
      const form = cardClone.querySelector('form');
      form.action = service.url;

      const input = form.querySelector('input[type="hidden"]');
      input.name = service.input_name;
      input.value = service.input_value;

      const card = cardClone.querySelector('.booking-card');
      card.dataset.elementValue = service.name;
      card.dataset.serviceName = service.name;

      const icon = cardClone.querySelector('.service-icon');
      icon.className = `fa service-icon ${service.icon_class}`;

      const serviceNameSpan = cardClone.querySelector('.service-name');
      serviceNameSpan.textContent = service.name;

      const descriptionSpan = cardClone.querySelector('.booking-widget-content');
      descriptionSpan.textContent = service.description;

      const priceSpan = cardClone.querySelector('.service-price');
      priceSpan.textContent = service.price;

      const durationSpan = cardClone.querySelector('.service-duration');
      if (service.service_duration) {
        durationSpan.classList.remove('hidden');
        durationSpan.textContent = service.service_duration;
      } else {
        durationSpan.classList.add('hidden');
      }

      const cardLink = cardClone.querySelector('.card-link');
      if (service.paused) {
        const pausedButtonClone = document.importNode(pausedButtonTemplate.content, true);
        const pausedButton = pausedButtonClone.querySelector('input[type="submit"]');
        pausedButton.value = service.booking_button_text;
        cardLink.innerHTML = '';
        cardLink.appendChild(pausedButtonClone);
      } else {
        const activeButtonClone = document.importNode(activeButtonTemplate.content, true);
        const activeButton = activeButtonClone.querySelector('input[type="submit"]');
        activeButton.value = service.booking_button_text;
        activeButton.dataset.eventId = `pt_service_selected_${service.slug}`;
        activeButton.dataset.selectedValue = service.name;
        cardLink.innerHTML = '';
        cardLink.appendChild(activeButtonClone);
      }

      serviceSelectionContainer.appendChild(cardClone);
    });
  }

  const resultsCountElement = document.getElementById('results-count');
  if (resultsCountElement) {
    resultsCountElement.textContent = data.results_count_locale;
  }

  const moreServicesContainer = document.getElementById('more-services');
  if (data.display_load_more) {
    const loadMoreTemplate = document.getElementById('load-more-link-template');
    const loadMoreClone = document.importNode(loadMoreTemplate.content, true);
    const loadMoreLink = loadMoreClone.querySelector('a');
    loadMoreLink.href = data.next_records_url;
    loadMoreLink.classList.add(...data.load_more_custom_classes.split(' '));
    moreServicesContainer.innerHTML = '';
    moreServicesContainer.appendChild(loadMoreClone);
  } else {
    moreServicesContainer.innerHTML = '';
  }
}
