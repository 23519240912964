import QueryString from "qs";

class TurbolinksAdapter {
  static isNativeHandlerAvailable() {
    const ua = window.navigator.userAgent;
    const iOSChromeBrowser = ua.indexOf('Safari') > 0 && ua.indexOf('CriOS') > 0;

    return this.isNua() || (this.isWebkit() && !iOSChromeBrowser);
  }

  static postMessage(data) {
    if (this.isNua()) {
      return nua.postMessage(JSON.stringify(data));
    } else if (this.isWebkit()) {
      return window.webkit.messageHandlers.nua.postMessage(data);
    }
  }

  static visit(url, options = {}) {
    console.log(`Turbolinks.visit, url = ${url}, options = ${options}`);

    let action = 'advance';
    if (options.action != null) {
      action = options.action;
    }

    let fullReload = false;
    if (options.fullReload != null) {
      fullReload = options.fullReload;
    }

    if (this.isNativeHandlerAvailable() && this.getNuaAction(action)) {
      this.postMessage({
        name: 'visitProposed',
        data: { location: url, action: this.getNuaAction(action), fullReload: fullReload }
      });
    } else {
      if (action != 'advance' && action != 'replace') {
        action = 'advance';
      }

      Turbolinks.visit(url, { action: action });
    }
  }
  static isLoggedIn() {
    if (!this.isNativeHandlerAvailable()) return;

    const metaTag = document.querySelector('meta[name=user-authentication]');
    const authenticated = metaTag != null && metaTag?.content == 'true';

    if (authenticated) {
      const userId = metaTag?.getAttribute('data-user-id');
      this.postMessage({ name: 'isLoggedIn', data: { result: 'true', userId: userId } });
      return { name: 'isLoggedIn', data: { result: true, userId: userId } };
    } else {
      this.postMessage({ name: 'isLoggedIn', data: { result: 'false' } });
      return { name: 'isLoggedIn', data: { result: false } };
    }
  }

  static loginSuccessful(userId) {
    // TODO This should be deprecated at some point in the future - not sure if that still works.

    if (!this.isNativeHandlerAvailable()) {
      this.postMessage({ name: 'loginSuccessful', data: { userId: userId } });
    }
  }

  static onLoginAttempt() {
    const email = $('#user_email').val();
    const password = $('#user_password').val();

    this.postMessage({
      name: 'loginAttempt',
      data: {
        email: email,
        password: password
      }
    });
  }

  static login(email, password) {
    Rails.ajax({
      url: '/users/sign_in.js',
      type: 'POST',
      data: QueryString.stringify({
        user: {
          email: email,
          password: password
        }
      }),
      success: function () {
        console.log('Attempt to log in succeeded');
      },
      error: function () {
        console.log('Attempt to log in failed');
      }
    });
  }

  static logout() {
    let url = $('meta[name="sign-out-url"]').attr('content');
    if (url == null) {
      url = '/users/sign_out';
    }

    Rails.ajax({
      url: url,
      type: 'DELETE',
      success: function () {
        Turbolinks.clearCache();
        this.postMessage({ name: 'onLogoutSuccessful' });
      },
      error: function () {
        this.postMessage({ name: 'onLogoutFailed' });
      }
    });
  }

  static siteConfiguration() {
    const siteConfigTag = $('meta[name=site-config]');
    const json = siteConfigTag.attr('content');

    if (json) {
      this.postMessage({ name: 'siteConfiguration', data: { site: json } });
    }
  }

  static buildMenu() {
    const $menuMetaTag = $('meta[name=app-menu]');
    const json = $menuMetaTag.attr('content');
    if (json) {
      const timestamp = $menuMetaTag.data('timestamp');
      this.postMessage({ name: 'buildMenu', data: { menu: json, timestamp: timestamp } });
    } else {
      const menus = [];
      const menuIds = [
        { id: 'data-mobile-menu-json', fixToBottom: false },
        { id: 'data-bottom-mobile-menu-json', fixToBottom: true }
      ];

      for (let i = 0; i < menuIds.length; i++) {
        const menuId = menuIds[i]['id'];
        const fixToBottom = menuIds[i]['fixToBottom'];

        const mainMenu = document.getElementById('main-menu')
        if (!mainMenu) {
          return;
        }

        const json = mainMenu.getAttribute(menuId);
        if (json == null || json.trim().length == 0) {
          continue;
        }

        const menuJSON = JSON.parse(json);
        const menu = {
          title: menuJSON['name'],
          type: 'header',
          hideTitle: true,
          fixToBottom: fixToBottom,
          items: []
        };

        for (let k = 0; k < menuJSON['menu_items'].length; k++) {
          const menuItemJSON = menuJSON['menu_items'][k];
          const icon_path = menuItemJSON['icon'];

          if (menuId == 'data-bottom-mobile-menu-json' && menuItemJSON['http_method'] == 'delete') {
            menu['items'].push({ type: 'jsAction', title: menuItemJSON['name'], action: 'logout', icon: icon_path });
          } else {
            menu['items'].push({
              type: 'link',
              title: menuItemJSON['name'],
              url: menuItemJSON['path'].replace(/^\//, ''),
              icon: icon_path,
              method: menuItemJSON['http_method']
            });
          }
        }

        menus.push(menu);
      }

      this.postMessage({ name: 'buildMenu', data: { menu: JSON.stringify(menus), timestamp: Date.now() } });
    }
  }

  static colourNavbar() {
    const colour = $("[name='mobile-header-colour']").attr('value');
    if (colour?.length) {
      this.postMessage({ name: 'colourNavBar', data: { colour: colour } });
    }
  }

  static hideBackButton() {
    const hideBackBtn = $("[name='mobile_hide_back_button']").attr('value');
    if (hideBackBtn) {
      this.postMessage({ name: 'hideBackButton', data: { result: hideBackBtn } });
    }
  }

  static openVideoConsultationRoom(data) {
    if (this.isNativeHandlerAvailable()) {
      this.postMessage({
        name: 'openVideoConsultationRoom',
        data: data
      });
    } else {
      TurbolinksAdapter.visit(data['url']);
    }
  }

  static onConnectAttempt(connectAttemptUrl) {
    // Future development VC analytics
  }

  static startedPublishingVideoConsultation(publishingStartUrl, connectionId = null) {
    // Future development VC analytics
  }

  static onDisconnectAttempt(disconnectAttemptUrl) {
    // Future development VC analytics
  }

  static stoppedPublishingVideoConsultation(publishingStopUrl, connectionId = null) {
    // Future development VC analytics
  }

  static nuaBaseActions() {
    return ['pop', 'modal', 'reload', 'resetRoot', 'pushBack'];
  }

  static nuaCompleteActions() {
    if (this.hasNuaAdditionalActions()) {
      return [...new Set([...nuaAdditionalActions, ...this.nuaBaseActions()])];
    } else {
      return this.nuaBaseActions();
    }
  }

  static getNuaAction(action) {
    if (this.nuaCompleteActions().indexOf(action) > 0) {
      return action;
    } else {
      return this.fallbackForNuaAction(action);
    }
  }

  static fallbackForNuaAction(action) {
    if (action == 'login') {
      return 'pop';
    } else {
      return null;
    }
  }

  static showInAppMessage() {}

  static isNua() {
    return window.nua !== undefined;
  }

  static isWebkit() {
    return window.webkit !== undefined;
  }

  static hasNuaAdditionalActions() {
    return window.nuaAdditionalActions !== undefined && window.nuaAdditionalActions != null;
  }
}

window.TurbolinksAdapter = TurbolinksAdapter;
