export default data => {
  const clinicListContainer = document.getElementById('clinic-selection');
  const resultsCountElement = document.getElementById('results-count');
  const moreClinicsElement = document.getElementById('more-clinics');

  // Handle results count
  if (data.clinics.length === 0) {
    resultsCountElement.textContent = '';
  } else {
    resultsCountElement.textContent = data.results_count_locale;
  }

  // Clear the existing list if there are no results or append is false
  if (data.clinics.length === 0 || !data.append) {
    clinicListContainer.innerHTML = '';
  }

  // Handle display_load_more
  if (!data.display_load_more) {
    // Clear the #more-clinics element
    moreClinicsElement.innerHTML = '';
  } else {
    // Replace #more-clinics content using the #load-more-link-template
    const loadMoreTemplate = document.getElementById('load-more-link-template').content.cloneNode(true);
    const loadMoreLink = loadMoreTemplate.querySelector('a');
    loadMoreLink.href = data.next_records_url;
    loadMoreLink.className = `hh-h6 ${data.load_more_custom_classes}`;

    // Replace #more-clinics content
    moreClinicsElement.innerHTML = '';
    moreClinicsElement.appendChild(loadMoreTemplate);
  }

  // If no clinics: render no results message
  if (data.clinics.length === 0) {
    const noResultsTemplate = document.getElementById('no-results-template').content.cloneNode(true);
    clinicListContainer.appendChild(noResultsTemplate);
    return;
  }

  // Otherwise render results
  data.clinics.forEach(clinic => {
    // Create a template element for each clinic using the `clinic-card-template`
    const template = document.getElementById('clinic-card-template').content.cloneNode(true);

    // Populate input attributes
    const clinicIdInput = template.querySelector('.clinic-id');
    clinicIdInput.value = clinic.id;
    clinicIdInput.name = `${clinic.scope}[clinic_id]`;

    // Set form action URL
    template.querySelector('form').action = clinic.url;

    // Update data attributes for element value and address
    const cardElement = template.querySelector('.booking-card.clinic-card.card');
    cardElement.dataset.elementValue = clinic.name;
    cardElement.dataset.elementAddress = clinic.address;

    // Set the clinic name
    template.querySelector('.clinic-name').textContent = clinic.name;

    // Set the clinic address
    const clinicAddressElement = template.querySelector('.clinic-address');
    if (clinic.hide_address) {
      clinicAddressElement.classList.add('hidden');
    } else {
      clinicAddressElement.classList.remove('hidden');
      template.querySelector('.clinic-address-text-color').textContent = clinic.address;
    }

    // Set the clinic distance
    const distanceContainerElement = template.querySelector('.clinic-distance-container');
    const distanceElement = template.querySelector('.clinic-distance');
    if (!clinic.distance) {
      distanceContainerElement.classList.add('hidden');
      distanceElement.textContent = '';
    } else {
      distanceContainerElement.classList.remove('hidden');
      distanceElement.textContent = clinic.distance;
    }

    // Set button text and data attributes for event tracking
    const buttonElement = template.querySelector('button[type="submit"]');
    buttonElement.textContent = clinic.button_text;
    buttonElement.dataset.eventId = `pt_clinic_selected_${clinic.slug}`;
    buttonElement.dataset.selectedValue = clinic.name;

    clinicListContainer.appendChild(template);
  });
};
