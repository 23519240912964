import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.gaTagValue = this.element.dataset.gaTag;
    if (this.GATagIsMissing()) return;

    this.loadGAScript();
    window.dataLayer = window.dataLayer || [];
    this.gtag('js', new Date());
    this.gtag('config', this.gaTagValue);
    const events = this.element.dataset.gaEvents;
    if (events !== undefined) {
      JSON.parse(events).forEach(event => {
        this.gtag('event', event.event_id, {
          cookie_flags: 'samesite=none;secure',
          screen_name: event.event_name
        });
      });
    } else {
      this.gtag('event', this.element.dataset.eventId, {
        cookie_flags: 'samesite=none;secure',
        screen_name: this.element.dataset.eventName
      });
    }
  }

  gtag() {
    if (this.GATagIsMissing()) return;
    dataLayer.push(arguments); // eslint-disable-line no-undef
  }

  onClick(e) {
    this.gtag('event', e.target.dataset.eventId, {
      screen_name: e.target.dataset.eventName,
      selected_value: e.target.dataset.selectedValue
    });
  }

  onLoad(e) {
    this.gtag('event', e.target.dataset.eventId, {
      screen_name: e.target.dataset.eventName
    });
  }

  loadGAScript() {
    const divScripts = document.head;
    const newScript = document.createElement('script');
    newScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.gaTagValue}`;
    newScript.async = '';
    newScript.nonce = document.querySelector('meta[name=csp-nonce]')?.content;
    divScripts.appendChild(newScript);
  }

  GATagIsMissing() {
    return this.gaTagValue === undefined || this.gaTagValue === null;
  }
}
