import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import updateClinicList from './shared/update_clinic_list.shared';
import updateServiceList from './shared/update_service_list.shared';

export default class extends Controller {
  static targets = ['link'];

  onClick = e => {
    e.preventDefault();

    const href = this.linkTarget.href;
    const [path, queryString] = href.split('?');

    Rails.ajax({
      type: 'GET',
      url: path,
      dataType: 'json',
      data: queryString,
      success: response => {
        if ('clinics' in response) {
          updateClinicList(response);
        } else if ('services' in response) {
          updateServiceList(response);
        }
      }
    });
  };
}
