import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export class SmartQuestionnaireFormControllerShared extends Controller {
  static targets = ['surveyContainer'];

  connect() {
    let widgetUrl = this.data.get('widget-url');
    let submitPath = this.data.get('submit-path');
    let url = this.data.get('url');
    let target = this.surveyContainerTarget;
    const webUrl = this.data.get('web-url');
    let onPageRendered = this.onPageRendered;

    this.resetSqFormParams();
    this.loadScript(widgetUrl).then(() => {
      let questionnaire = new window.mh.MedihiveSmartQuestionnaire(url, target, false);
      this.installNuaSqStylesheet(webUrl);
      questionnaire.render();
      document.addEventListener('mh-sq:load', onPageRendered);
      document.removeEventListener('mh-sq:finish', questionnaire.onQuestionnaireFinished);
    });
    this.saveAnswers(submitPath);
  }

  loadScript(src) {
    return new Promise(function (resolve, reject) {
      var s;
      s = document.createElement('script');
      s.src = src;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    });
  }

  disconnect() {
    this.removeListeners();
  }

  addListeners() {
    document.addEventListener('mh-sq:expired-session', this.onExpiredSession);
  }

  removeListeners() {
    document.removeEventListener('mh-sq:expired-session', this.onExpiredSession);
  }

  saveAnswers(submitPath) {
    document.addEventListener('mh-sq:finish', function () {
      Rails.ajax({
        type: 'POST',
        url: submitPath
      });
    });
  }

  onPageRendered = () => {
    $('.sq-confirm-answer-btn').hide();
  };

  onExpiredSession = () => {
    window.location.reload();
  };

  installNuaSqStylesheet = nuaSqWebUrl => {
    let stylesheetUrl = new URL(`${nuaSqWebUrl}/widget/application-embedded.css`);
    let style = document.querySelector(`link[href="${stylesheetUrl}"]`);
    if (style) return;

    style = document.createElement('link');
    style.rel = 'stylesheet';
    style.href = stylesheetUrl;

    let nonce = document.querySelector('meta[name="csp-nonce"]');
    if (nonce) {
      style.nonce = nonce;
    }

    document.head.insertAdjacentElement('beforeend', style);
  };
}
