import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import { Helpers } from '../../utils/helpers';

export class QuestionnaireFormControllerShared extends Controller {
  static targets = ['form'];

  onAnswerChange = Helpers.debounce(() => {
    this.evaluateForm();
  }, 100);

  evaluateForm = () => {
    if (!this.shouldEvaluate()) return;
    if (!this.data.get('evaluation-url')) return;

    this.submitAnswers(this.data.get('evaluation-url'));
  };

  onSubmit = e => {
    e.preventDefault();

    this.submitAnswers(this.formTarget.action);
  };

  submitAnswers = url => {
    Rails.ajax({
      dataType: 'json',
      type: 'POST',
      url: url,
      data: $('form').serialize(),
      success: data => {
        if (data.redirectTo) {
          window.location.href = data.redirectTo;
        } else if (data.html) {
          document.getElementById('questionnaire').innerHTML = data.html;
          document.dispatchEvent(new CustomEvent('disable-submit-overlay:hide-overlay', { bubbles: true }));
        } else if (data.sidebar?.openUrl) {
          document
            .querySelector('.sidebar')
            .dispatchEvent(new CustomEvent('sidebar:open-url', { detail: { url: data.sidebar.openUrl } }));
        }
      }
    });
  };

  shouldEvaluate = () => {
    return this.data.get('should-evaluate') === 'true';
  };
}
